import { Accordion, AccordionDetails, AccordionSummary, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Services() {
    const theme = useTheme();
    const isMidScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const services = [
        { title: 'Cloud Solutions', content: 'Maximize operational efficiency and reduce overhead costs with our tailored cloud solutions. We design and implement cloud architectures that integrate storage, backup, and hosting services, allowing you to leverage cloud technology to scale your business with ease. Our team helps you adopt a hybrid or fully cloud-based approach to ensure high availability and security. Whether for backup or full-scale deployment, we create a seamless cloud experience suited to your needs. We work with leading cloud platforms to ensure optimal performance and cost-efficiency.' }, // Replace with actual content
        { title: 'Linux Solutions', content: 'Unlock the full potential of open-source technologies with our Linux solutions, designed for flexibility and performance. We provide end-to-end services, including deployment, configuration, and management, for all major distributions such as Ubuntu, RedHat and Amazon Linux. Our solutions ensure a secure, efficient, and scalable environment for businesses of any size. We support both cloud-based and on-premise setups, helping you streamline your IT infrastructure. Our Linux expertise guarantees stability and reliability for mission-critical applications.' }, // Replace with actual content
        { title: 'Infrastructure Management & Migration', content: 'Modernize your IT landscape with our infrastructure management and migration services, designed to streamline operations. From upgrades to cloud transitions, we handle all aspects of your infrastructure lifecycle. Our team ensures a smooth, hassle-free migration with minimal downtime, allowing your business to keep moving forward. We also provide ongoing maintenance and support to keep your systems running at peak performance. Our solutions are designed to reduce risk while ensuring scalability and security.' }, // Replace with actual content
        { title: 'Backup & Disaster Recovery Solutions', content: 'Safeguard your critical data with our advanced backup and disaster recovery solutions, crafted to ensure business continuity. We offer customized backup strategies that protect against data loss, along with rapid recovery options that minimize downtime during disasters. Our services are designed to be scalable and adaptable to meet the needs of your business, no matter the size or industry. With proactive monitoring and regular testing, we ensure your systems are always prepared for the unexpected. Trust us to safeguard your data with reliable and efficient solutions.' }, // Replace with actual content
        { title: 'Centralized Identity Management', content: 'Streamline your network security with our Active Directory (AD) setup and centralized identity management services. We offer tailored AD solutions that simplify user authentication, authorization, and policy enforcement. Our team ensures smooth integration of AD across your network, improving access control and reducing the risk of unauthorized access. We help you maintain a secure, compliant IT environment while improving operational efficiency. Our solutions support both on-premise and cloud environments, providing flexibility and enhanced security.' }, // Replace with actual content
    ];

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid2 container spacing={2} justifyContent="center" alignItems="center" id="services">
            <Grid2 item xs={12}>
                <Typography textAlign="center" variant={isMidScreen ? "h4" : "h3"} fontWeight="bold" color="#0c0c5b" gutterBottom>
                    Our Services
                </Typography>
            </Grid2>
            {services.map((service, index) => (
                <Grid2 item xs={12} key={service.title}>
                    <Accordion expanded={expanded === service.title} onChange={handleChange(service.title)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#E8E8E8" }}>
                            <Typography variant="h6">{service.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {/* <Paper elevation={3} sx={{ p: 4 }}> */}
                            <Typography variant="body1" component="p" p={2} sx={{ color: "grey.600" }}>
                                {service.content}
                            </Typography>
                            {/* </Paper> */}
                        </AccordionDetails>
                    </Accordion>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default Services;