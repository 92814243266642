import { Box, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import about_img from "../../images/about.png";

function AboutUs() {
    const theme = useTheme();
    const isMidScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const cardData = [
        {
            title: "Fast Executions",
            description: "We understand that time is of the essence, and we pride ourselves on delivering fast execution without compromising on quality. Our streamlined processes, expert teams, and agile methodologies enable us to complete projects on schedule, ensuring that you experience minimal disruption and can capitalize on new opportunities quickly.",
        },
        {
            title: "Certified Expertise",
            description:
                "Our team consists of AWS Certified, Red Hat Certified, and Azure Certified professionals, guaranteeing that you benefit from the highest level of technical proficiency and industry-standard best practices.",
        },
        {
            title: "Proven Track Record",
            description:
                "With extensive experience in handling government projects and delivering successful AWS deployments, we bring a proven track record of executing large-scale, complex IT solutions. Our expertise across multiple sectors enables us to address diverse challenges, ensuring that every project we undertake meets your business needs efficiently and effectively.",
        },
        {
            title: "Dedicated Support",
            description:
                "We offer round-the-clock, dedicated support, ensuring that your IT infrastructure remains stable, secure, and optimized. Our team works closely with you to troubleshoot issues, provide updates, and proactively address any concerns, giving you peace of mind that your IT environment is in safe hands.",
        },
        {
            title: "Cutting-Edge Technology",
            description:
                "We utilize the latest advancements in virtualization, automation, and cloud computing to deliver innovative solutions that give your business a competitive edge. By integrating modern technologies, we ensure that your systems are not only efficient but also adaptable to the rapidly changing technological landscape.",
        },
    ];

    return (
        <Grid2 size={12} container id="aboutus" p={isMidScreen ? 3 : 7}>
            {/* About Us Title */}
            <Grid2 size={12} mt={8}>
                <Typography
                    textAlign="center"
                    variant={isMidScreen ? "h4" : "h3"}
                    fontWeight="bold"
                    color="#0c0c5b"
                    gutterBottom
                >
                    About Us
                </Typography>
            </Grid2>

            {/* About Us Content */}
            <Grid2 size={12} container>
                <Box borderRadius={3} boxShadow={6} p={1}>
                    <Grid2 size={12} container columnSpacing={3}>
                        <Grid2 item size={{ xs: 12, md: 5 }} p={2}>
                            <img
                                src={about_img}
                                alt="Team collaboration and expertise"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: "8px",
                                    objectFit: "cover",
                                }}
                            />
                        </Grid2>
                        <Grid2 item size={{ xs: 12, md: 7 }} alignContent="center" p={2} sx={{ color: "grey.600" }}>
                            <Typography fontSize={isMidScreen ? 17 : 20} gutterBottom>
                                At ItechExs, we are a team of passionate IT professionals with over a decade of experience 
                                delivering cutting-edge solutions. Our expertise spans a wide range of sectors, from 
                                government initiatives to high-profile AWS deployments. We pride ourselves on providing 
                                comprehensive IT services tailored to meet the unique needs of each client. Whether you require 
                                cloud solutions, infrastructure management, or robust disaster recovery, we have the expertise 
                                to elevate your business. We stay at the forefront of emerging technologies, ensuring that 
                                your IT environment remains secure, scalable, and cost-effective.
                            </Typography>
                            <Typography fontSize={isMidScreen ? 17 : 20} gutterBottom mt={3}>
                                With a customer-first approach, we work closely with you to design solutions that drive 
                                efficiency and foster growth. Our team's technical proficiency, coupled with our 
                                commitment to excellence, ensures you receive optimal solutions. We build lasting 
                                partnerships that help businesses thrive in a digital world. We specialize in 
                                both cloud and on-premise environments, providing flexible and innovative strategies.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>

            {/* Why Choose Us */}
            <Grid2 size={12} mt={6} container>
                <Grid2 item size={12}>
                    <Typography
                        textAlign="center"
                        variant={isMidScreen ? "h4" : "h3"}
                        fontWeight="bold"
                        color="#0c0c5b"
                        gutterBottom
                    >
                        Why Choose Us!
                    </Typography>
                </Grid2>
                <Grid2
                    item
                    size={12}
                    container
                    rowSpacing={2}
                    columnSpacing={3}
                    mt={2}
                    justifyContent="center"
                >
                    {cardData.map((item, index) => (
                        <Grid2 key={index} item size={{ xs: 12, md: 6, lg: 4 }}>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    boxShadow: 2,
                                    borderRadius: 3,
                                    p: 2,
                                    minHeight: { lg: "215px" }, // Dynamic height
                                }}
                            >
                                <Typography variant="h5" fontWeight="bold" color="#0c0c5b" gutterBottom>
                                    {item.title}
                                </Typography>
                                <Typography gutterBottom sx={{ color: "grey.600" }}>
                                    {item.description}
                                </Typography>
                            </Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default AboutUs;
