import { Box, Grid2, Typography, useMediaQuery } from "@mui/material";
import hero_section_illustration from '../../images/hero-section-illustration.png';
import AboutUs from "./AboutUs";
import Services from "./Services";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Home() {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMidScreen = useMediaQuery('(max-width:1200px)');
    return (
        <div>
            <Box sx={{ backgroundColor: "#f5f5f5", padding: isSmallScreen ? 2 : 4 }} id="home" className="home-section">
                <Grid2 size={12} mt={6}>
                    <Header />
                </Grid2>
                <Grid2 container alignItems="center" spacing={3} mt={isMidScreen ? 10 : 0}>
                    <Grid2 item size={{ xs: 12, lg: 6 }}>
                        <Typography variant="h4" color="primary" gutterBottom>
                            Welcome to ItechExs
                        </Typography>
                        <Typography variant={isSmallScreen ? 'h4' : 'h2'} fontWeight="bold" color="#0c0c5b" gutterBottom>
                            Empowering Your IT Infrastructure with Expertise and Innovation
                        </Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, lg: 6 }}>
                        <img src={hero_section_illustration} alt="Hero Section" style={{ width: "100%" }} />
                    </Grid2>
                </Grid2>
                <Grid2 container alignItems="center" spacing={3} size={12}>
                    <AboutUs />
                </Grid2>
                <Grid2 container alignItems="center" spacing={3} size={12}>
                    <Services />
                </Grid2>
            </Box>
            <Grid2 container alignItems="center" spacing={3} size={12} >
                <Footer />
            </Grid2>
        </div>
    );
}
export default Home;